/** @format */

import { ExternalLink, InternalLink } from '@/types/page';
import { AnimatedCardEntry } from '@/types/shared';
import { Document } from '@contentful/rich-text-types';
import { Entry } from 'contentful';
import { FunctionComponent as FC, useContext, useEffect, useRef } from 'react';
import { ThemeContext, styled } from 'styled-components';
import type { Swiper as SwiperType } from 'swiper';
import { StyledButton } from '../ui/Button';
import { Container, ContainerWrapper } from '../ui/Layout';
import { AwardCard } from './Card';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import { Keyboard } from 'swiper/modules';
import { FindColor } from '../../utils/findThemeColor';
import { RichText } from '../ui/RichText';

import { Swiper, SwiperSlide } from 'swiper/react';

export const AwardsCardsSection: FC<Props> = ({ title, cards, link, background }) => {
  const theme = useContext(ThemeContext);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const swiperRef = useRef<SwiperType | null>(null);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const computedStyle = getComputedStyle(containerRef.current);
        const marginLeft = parseInt(computedStyle.marginLeft);
        const marginRight = parseInt(computedStyle.marginLeft);

        swiperRef.current!.params.slidesOffsetBefore = marginLeft;
        swiperRef.current!.params.slidesOffsetAfter = marginRight;
        swiperRef.current?.update();
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ContainerWrapper $color={theme!.color.teal.dark}>
      <PlaceholderContainer ref={containerRef} />
      <Wrapper $background={FindColor(background ?? 'white', theme?.color)}>
        {title && (
          <TextWrapper>
            <RichText text={title} />
          </TextWrapper>
        )}
        <CardList>
          <Swiper
            onSwiper={swiper => (swiperRef.current = swiper)}
            slidesPerView={'auto'}
            updateOnWindowResize={true}
            keyboard={{
              enabled: true,
            }}
            mousewheel={{
              forceToAxis: true,
            }}
            spaceBetween={undefined}
            breakpoints={{
              320: {
                spaceBetween: 16,
                centerInsufficientSlides: false,
              },
              360: {
                spaceBetween: 16,
                centerInsufficientSlides: false,
              },
              768: {
                spaceBetween: 16,
              },
              992: {
                spaceBetween: 30,
              },
              1200: {
                spaceBetween: 30,
              },
              1440: {
                centerInsufficientSlides: false,
                spaceBetween: 30,
              },
            }}
            modules={[Keyboard]}
          >
            {cards &&
              cards.length > 0 &&
              cards.map((card, index) => (
                <SwiperSlide key={index}>
                  <AwardCard card={card} />
                </SwiperSlide>
              ))}
          </Swiper>
        </CardList>
        {link && (
          <ButtonWrapper>
            <StyledButton elementType={'magic'} item={link} variant={'bgYellow'} position={'right'} />
          </ButtonWrapper>
        )}
      </Wrapper>
    </ContainerWrapper>
  );
};

const PlaceholderContainer = styled(Container)`
  padding: 0;
`;

const Wrapper = styled.div<{ $background: string }>`
  overscroll-behavior-x: none;
  background-color: ${({ $background }) => $background};

  .swiper {
    overscroll-behavior-x: none;
  }
`;

const TextWrapper = styled.div`
  padding-top: clamp(3.56rem, 3.12rem + 1.9556vw, 4.88rem);
  padding-bottom: clamp(2.64rem, 1.8933rem + 3.3185vw, 4.88rem);
  padding-inline: clamp(1.56rem, 0.83rem + 3.2444vw, 3.75rem);
  text-align: center;

  h2 {
    color: ${({ theme }) => theme.color.white};
  }
  p {
    color: ${({ theme }) => theme.color.white};
  }
`;

const CardList = styled.div`
  position: relative;
  padding-bottom: clamp(3.56rem, 3.12rem + 1.9556vw, 4.88rem);

  .swiper-slide {
    width: 17.375rem;
    min-width: 17.375rem;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
  padding-bottom: clamp(3.75rem, 3.1633rem + 2.6074vw, 5.51rem);
  padding-inline: clamp(1.56rem, 0.83rem + 3.2444vw, 3.75rem);
`;

type Props = {
  title?: Document;
  cards: AnimatedCardEntry[];
  link?: Entry<InternalLink['fields']> | Entry<ExternalLink['fields']>;
  background?: string;
};
