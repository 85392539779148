/** @format */

import { Image } from '@/components/Image';
import { useMediaQueryIndex } from '@/hooks/useMediaQuery';
import { AnimatedCard } from '@/types/shared';
import { Entry } from 'contentful';
import { AnimatePresence, motion } from 'framer-motion';
import { FunctionComponent as FC, useState } from 'react';
import styled from 'styled-components';
import { RichText } from '../ui/RichText';

export const AwardCard: FC<Props> = ({ card }) => {
  const { svg, title, text, image } = card.fields;
  const [isHover, setIsHover] = useState(false);
  const mediaQueryIndex = useMediaQueryIndex();
  const mobile = !!(mediaQueryIndex !== null && mediaQueryIndex < 2);

  return (
    <CardWrapper
      onMouseLeave={() => setIsHover(false)}
      onMouseEnter={() => setIsHover(true)}
      whileTap="hover"
      whileHover="hover"
      onClick={() => mobile && setIsHover(!isHover)}
      className={isHover ? 'CardWrapper__hover' : ''}
    >
      <ImageWrapper
        initial={{
          opacity: 1,
        }}
        animate={{
          opacity: isHover ? 0 : 1,
          transition: {
            duration: !isHover ? 0.3 : 0.03,
            delay: !isHover ? 0.2 : 0,
          },
        }}
        transition={{
          cubic: [0.36, 0.33, 0, 1],
        }}
      >
        {svg ? <div dangerouslySetInnerHTML={{ __html: svg }} /> : image && <Image image={image} />}
      </ImageWrapper>

      <AnimatePresence>
        {isHover && (
          <motion.div
            initial={{
              position: 'absolute',
              left: 0,
              top: '100%',
            }}
            animate={{ top: '1.44rem' }}
            exit={{ top: '100%' }}
            transition={{
              duration: 0.3,
              cubic: [0.36, 0.33, 0, 1],
            }}
          >
            <TextWrapper>
              <RichText text={text} />
            </TextWrapper>
          </motion.div>
        )}
      </AnimatePresence>
    </CardWrapper>
  );
};

const ImageWrapper = styled(motion.div)`
  overflow: hidden;
  opacity: 1;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

  img {
    height: auto;
    width: 100%;
  }
`;

const TextWrapper = styled.div`
  padding-inline: 1.44rem;
  p {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5rem; /* 133.333% */
    letter-spacing: 0.03375rem;
    color: ${({ theme }) => theme.color.white};
  }
`;

const CardWrapper = styled(motion.div)`
  position: relative;
  border-radius: 1.25rem;
  padding-inline: 1.44rem;
  width: 100%;
  min-height: 15.25rem;
  max-height: 15.25rem;
  height: 15.25rem;
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.white};
  transition: background-color 0.3s cubic-bezier(0.36, 0.33, 0, 1) 0.1s;
  display: grid;
  place-content: center;

  &.CardWrapper__hover {
    background-color: ${({ theme }) => theme.color.teal.light};
    transition: background-color 0.3s cubic-bezier(0.36, 0.33, 0, 1);
  }
`;

type Props = {
  card: Entry<AnimatedCard>;
};
